.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loading-wrapper-main {
  height: 100vh !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}

span.special-vip.member-style-gold {
  background-color: #fec84e;
  background-image: linear-gradient(
    to right,
    #bf953f,
    #fcf6ba,
    #b38728,
    #fbf5b7,
    #aa771c
  );
  color: #3f3f3f;
}
.btn {
  background: #d47b39 !important;
  color: #fff;
  letter-spacing: 0.5px;
  font-size: 17px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 2px;
  padding: 10px 20px;
  -webkit-appearance: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  box-shadow: 3px 3px 7px #f0c7a8;
  border-radius: 5px;
}
.btn-interest {
  padding: 4px 16px;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 400;
  background-color: #d4b039 !important;
}
.slick-arrow {
  background-color: #d47b39;
  color: #fff;
}

.custom-next {
  position: absolute;
  top: 50%;
  right: -5rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  outline: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  font-size: 22px;
  color: #c9c9c9;
  background-color: #d47b39;
}
.custom-prev {
  position: absolute;
  top: 50%;
  left: -5rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  outline: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  font-size: 22px;
  color: #c9c9c9;
  background-color: #d47b39;
}
.custom-next i,
.custom-prev i {
  color: #fff;
  position: relative;
  top: 9px;
}
