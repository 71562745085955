@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: "DM Sans", sans-serif !important;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wrapper {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-main {
  position: relative;
  display: inline-block;
}

.video {
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 100%;
  background: transparent;
  color: #fff;
  display: inline-block;
  background: #963aee;
  z-index: 999;
}

@keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }
  100% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

.fa-play:before {
  content: "\f04b";
}

.waves {
  position: absolute;
  width: 150px;
  height: 150px;
  background: #963aeea1;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  border-radius: 100%;
  right: -50px;
  bottom: -50px;
  z-index: -1;
  -webkit-animation: waves 3s ease-in-out infinite;
  animation: waves 3s ease-in-out infinite;
}

.wave-1 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.wave-2 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.wave-3 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.lang-menu {
  width: 100px;
  text-align: right;
  font-weight: bold;
  margin-top: 25px;
  position: relative;
}
.lang-menu .selected-lang {
  display: flex;
  justify-content: space-between;
  line-height: 2;
  cursor: pointer;
}
.lang-menu .selected-lang:before {
  content: "";
  display: inline-block;
  width: 32px;
  height: 32px;
  background-image: url(https://www.countryflags.io/us/flat/32.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.lang-menu ul {
  margin: 0;
  padding: 0;
  display: none;
  background-color: #fff;
  border: 1px solid #f8f8f8;
  position: absolute;
  top: 45px;
  right: 0px;
  width: 125px;
  border-radius: 5px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
}

.lang-menu ul li {
  list-style: none;
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.lang-menu ul li a {
  text-decoration: none;
  width: 125px;
  padding: 5px 10px;
  display: block;
}

.lang-menu ul li:hover {
  background-color: #f2f2f2;
}

.lang-menu ul li a:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align: middle;
  margin-right: 10px;
  background-size: contain;
  background-repeat: no-repeat;
}

.de:before {
  background-image: url(https://www.countryflags.io/de/flat/32.png);
}

.en:before {
  background-image: url(https://www.countryflags.io/us/flat/32.png);
}
.fr:before {
  background-image: url(https://www.countryflags.io/fr/flat/32.png);
}

.ar:before {
  background-image: url(https://www.countryflags.io/ae/flat/32.png);
}

.lang-menu:hover ul {
  display: block;
}
